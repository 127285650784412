<template>
    <div class="sidebar">
        <img class="logo" src="https://mouseion.aadhum.org/wl/?id=sDMF4OobbBsnb7cuTRXa1qVkajxjSa6a&fmode=open" alt="AADHum Logo">
        <div class="sidebarText">
            <h4 class="title">{{ foo.title }}</h4>
            <p v-html="foo.description"></p>
        </div>
        <a href="#nav" class="backToTop center">
            <i class="Large material-icons topArrow">keyboard_arrow_up</i>
            <p class="topText">back to top</p>
        </a>
    </div>
</template>

<script>
    export default {
        name: "Sidebar",
        props: ['foo'],
    }
</script>

<style scoped lang="scss">
    .sidebar {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        
        .title {
            writing-mode: vertical-rl;
            text-orientation: mixed;
            transform: rotate(180deg);
            font-size: 24px;
            font-family: 'Alioli', fantasy;
            margin: 0;
            padding: 0 0 16px 0;
            color: #383838;
            @media only screen and (max-width: 1300px) {
                margin: 20px 0;
                font-size: 22px;
            }
            @media only screen and (max-width: 800px) {
                margin: 12px 0;
                font-size: 26px;
                writing-mode: horizontal-tb;
                text-orientation: upright;
                transform: rotate(0deg);
                padding: 0;
            }
        }
        
        .logo {
            width: 275px;
            @media only screen and (max-width: 1000px) {
                width: 200px;
                // padding-bottom: 40px;
            }
            @media only screen and (max-width: 800px) {
                display: none;
                // padding-bottom: 40px;
            }
        }
    }
    
    .center {
        width: 100%;
        text-align: center;
    }
    
    .topArrow {
        font-size: 36px;
        background-color: #e2e2e2;
        border-radius: 20px;
        margin: 0 auto;
    }
    
    .topText {
        color: #8f8f8f;
        font-size: 18px;
        margin-top: 8px;
        
        &:hover {
            color: #cf5c5c;
        }
    }
    
    a {
        color: black;
        text-decoration: none;
    }
    
    .backToTop {
        width: 100px;
        @media only screen and (max-width: 1100px) {
            display: none;
        }
    }
    
    p {
        font-size: 18px;
        margin-top: 8px;
    }
    
    .sidebarText {
        display: flex;
        align-items: center;
        padding-top: 8px;
        grid-gap: 10px;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
            padding: 0;
        }
    }
</style>